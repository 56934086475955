<template>
    <section>
        <article>
            <h3>{{ $t('listSection.title') }}</h3>

            <p>
                {{ $t('listSection.descriptionPart1') }}
                <strong>{{ $t('listSection.strongPart') }}</strong>
                {{ $t('listSection.descriptionPart2') }}
            </p>

            <ul>
                <li>{{ $t('listSection.list.list1') }}</li>
                <li>{{ $t('listSection.list.list2') }}</li>
                <li>{{ $t('listSection.list.list3') }}</li>
                <li>{{ $t('listSection.list.list4') }}</li>
                <li>{{ $t('listSection.list.list5') }}</li>
            </ul>
        </article>

        <img src="/primer-plano-pluma-sobre-investigacion-mercado 1.png" alt="pluma investigación">

    </section>
</template>


<script>
export default {
    name: 'ListSection'
}
</script>


<style scoped>
h3,
p,
img {
    text-align: start;
    margin: 0px;
}

img {
    display: block;
    width: 44px;
}

section p {
    margin-top: 16px;
    margin-bottom: 36px;
}

section img {
    display: block;
    margin-top: 20px;
    width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0px;
}

ul li {
    padding-left: 20px;
    background: url('../../public/Badge\ Check.svg') left center no-repeat;
    background-size: 18px;
    margin-bottom: 10px;
    text-align: start;

}

@media (min-width: 834px) {
    section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    section img {
        width: 367px;
        height: 340px;
        object-fit: cover;
        margin-left: 20px;
        margin-top: 0;
    }

}
</style>