const getSaleValues = (saleDistribution) => {
    return [
        saleDistribution.area_0 || 0,
        saleDistribution.area_100 || 0,
        saleDistribution.area_200 || 0,
        saleDistribution.area_300 || 0,
        saleDistribution.area_400 || 0,
        saleDistribution.area_500 || 0
    ];
}

export default getSaleValues