<template>
    <div class="container chart" v-if="isDataAvailable">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-else>
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>{{ $t('errorChart.title') }}</h5>
            <h6>{{ $t('errorChart.text') }}<strong> {{ $t('errorChart.strong') }}</strong>
            </h6>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'PopulationByAgeChart',
    computed: {
        ...mapState([ 'areaData' ]),
        isDataAvailable() {
            return this.areaData.population !== 0 && this.areaData.malePopulationByAge && this.areaData.femalePopulationByAge
        }
    },
    data() {
        return {
            series: [ {
                name: this.$t('populationByAgeChart.seriesName'),
                data: []
            } ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: [ 'transparent' ]
                },
                xaxis: {
                    categories: [ '0-20', '21-40', '41-60', '61-105' ],
                    title: {
                        text: this.$t('populationByAgeChart.xAxis')
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('populationByAgeChart.yAxis')
                    },
                    min: 0
                },
                fill: {
                    opacity: 1,
                    colors: [ '#268b74' ]
                },
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return `${val} ${this.$t('populationByAgeChart.tooltip')}`
                        }
                    }
                }
            }
        }
    },
    methods: {
        groupByAgeRange() {
            const ageRanges = [ 0, 0, 0, 0 ]
            const ageGroups = [ [ 0, 1, 2, 3 ], [ 4, 5, 6, 7 ], [ 8, 9, 10, 11 ], [ 12, 13, 14, 15, 16, 17, 18, 19, 20 ] ]
            const maleData = this.areaData.malePopulationByAge || []
            const femaleData = this.areaData.femalePopulationByAge || []
            const combinedData = maleData.map((val, index) => val + (femaleData[ index ] || 0))


            combinedData.forEach((val, index) => {
                ageGroups.forEach((group, rangeIndex) => {
                    if (group.includes(index)) {
                        ageRanges[ rangeIndex ] += val
                    }
                })
            })

            return ageRanges
        }
    },
    mounted() {
        if (this.isDataAvailable) {
            this.series[ 0 ].data = this.groupByAgeRange()
            console.log(this.groupByAgeRange())
        }
    }
}
</script>


<style scoped>
.container-chart {
    width: 562px;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}
</style>