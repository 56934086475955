<template>
    <div class="footer-img">
        <a href="https://nexteugeneration.com/" target="blank">
            <img src="/logo-europa.svg" alt="next generation">
        </a>
        <a href="https://portal.mineco.gob.es/es-es/Paginas/index.aspx" target="blank">
            <img src="/gobierno-de-espana-web-stroke.svg" alt="">
        </a>
        <a href="https://planderecuperacion.gob.es/" target="blank">
            <img src="/logo-gobierno.svg" alt="plan de recuperación">
        </a>
        <a href="https://www.vitoria-gasteiz.org/wb021/was/we001Action.do?accionWe001=ficha&accion=home" target="blank">
            <img src="/pie-logo-tourism.svg" alt="ayuntamiento de vitoria">
        </a>

        <div class="footer-bottom">

            <h5>© 2024 Ayuntamiento de Vitoria-Gasteiz</h5>

            <div class="flex">
                <h5><a href="https://sedeelectronica.vitoria-gasteiz.org/j30-01s/contenidoAction.do?locale=es&lang=es&idioma=es&uid=u_7db76920_1438a3e2bf7__7f9e"
                        target="blank">Política
                        de privacidad</a>
                </h5>
                <h5><a href="https://www.vitoria-gasteiz.org/wb021/was/contenidoAction.do?idioma=es&uid=141d8b27_11b7c821284__7ff8&accion=contenido"
                        target="blank">Aviso
                        legal</a></h5>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Footer'
}
</script>


<style scoped>
.footer-img {
    background-color: black;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

img {
    display: block;
    width: 80%;
    max-width: 300px;
    height: auto;
    max-height: 80px;
    margin: auto;
}

h5 {
    margin: 0 0 12px 0;
    text-align: center;
    font-weight: 400;
    color: white;
}

a {
    text-decoration: none;
    color: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.footer-bottom {
    margin: 10px auto 0;
}

@media (min-width: 834px) {
    .footer-img {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }

    img {
        width: 20%;
    }

    .footer-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    h5 {
        margin-left: 0;
        display: inline;
    }

    .flex {
        justify-content: space-between;
        align-items: center;
        gap: 30px;
    }
}

@media (min-width: 1280px) {
    .footer-img {
        gap: 20px;
    }

    img {
        width: 270px;
    }
}
</style>
