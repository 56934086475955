<template>
    <div class="flex">

        <div class="data-card-container margin">
            <DataCardInfo :title="$t('dataCard.title1')" :data="formatNumber(areaData.population)"
                :detail="$t('dataCard.detail1')" />
        </div>

        <div class="data-card-container">
            <DataCardInfo :title="$t('dataCard.title2')"
                :data="formatNumber(areaData.population) == 0 ? '0' : formatNumber(areaData.rent)"
                :detail="$t('dataCard.detail2')" />
        </div>

    </div>
</template>


<script>
import DataCardInfo from './DataCardInfo.vue'
import { mapState } from 'vuex'

export default {
    name: 'DataCard',
    components: {
        DataCardInfo
    },
    computed: {
        ...mapState([ 'areaData' ]),
    },
    methods: {
        formatNumber(number) {
            return number.toLocaleString('es-ES')
        },
    }
}
</script>


<style scoped>
.margin {
    margin-bottom: 20px;
}

.data-card-container {
    padding: 28px 44px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    width: 100%;

}

@media (min-width: 834px) {
    .margin {
        margin-bottom: 0;
        margin-right: 20px;
    }

    .flex {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 1280px) {
    .flex {
        justify-content: flex-start;
        padding-bottom: 20px;
    }
}
</style>