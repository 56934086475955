<template>
    <div id="map" class="map"></div>
</template>


<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import { useMessage } from "naive-ui"
import mapboxgl from 'mapbox-gl'
import getCircularArea from '../utils/getCircularArea'
import formatPopulationByAge from '../utils/formatPopulationByAge'
import getTradeMarkers from '../utils/getTradeMarkers'
import isPointInsideVit from '../utils/isPointInsideVit'
import getTopMarkers from '../utils/getTopMarkers'
import apiService from '../services/api.service'
import vitoriaCoordinates from '../utils/vitoriaCoordinates'
import getAllMarkers from '../utils/getAllMarkers'

export default {
    name: 'MapBox',
    computed: {
        ...mapState([ 'selectedLngLat', 'address' ])
    },
    data() {
        return {
            map: null,
            marker: null,
            markerImage: null,
            message: useMessage()
        }
    },
    methods: {
        ...mapActions([ 'setSelectedLngLat', 'setAddress', 'setAreaRent', 'setMalePopulationByAge', 'setFemalePopulationByAge', 'setAreaPopulation', 'setMarkers', 'setFailedReport', 'setConsumption', 'setRentalDistribution', 'setSaleDistribution' ]),
        async initializeMap() {

            this.map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/zzdatalabs/clp9m7etx002h01r56oaxg2q6',
                center: [ -2.67268, 42.84998 ],
                zoom: 8,
                attributionControl: false
            })

            this.map.addControl(new mapboxgl.AttributionControl({ compact: true }))


            this.map.on('load', async () => {

                this.setSelectedLngLat({
                    lng: -2.67268,
                    lat: 42.84998
                })

                await this.map.on('click', async (e) => {

                    this.setFailedReport(false)

                    const selectedLngLat = {
                        lng: e.lngLat.lng,
                        lat: e.lngLat.lat
                    }
                    this.setAreaPopulation(null)

                    const isInVitoria = isPointInsideVit([ selectedLngLat.lng, selectedLngLat.lat ])

                    if (isInVitoria) {
                        this.setSelectedLngLat(selectedLngLat)
                    } else {
                        this.message.warning("Selecciona una dirección en el área delimitada en el mapa", { duration: 2000 })
                    }

                })

                this.map.addLayer({
                    id: 'vitoria-area-layer',
                    type: 'fill',
                    source: {
                        type: 'geojson',
                        data: vitoriaCoordinates,
                    },
                    paint: {
                        'fill-color': '#CBCBCB',
                        'fill-opacity': 0.5,
                    },
                })
                this.map.addLayer({
                    id: 'vitoria-area-border-layer',
                    type: 'line',
                    source: {
                        type: 'geojson',
                        data: vitoriaCoordinates,
                    },
                    paint: {
                        'line-color': '#00775c',
                        'line-width': 2,
                    },
                })

            })
        },
        addMarker() {

            if (this.map && this.map.hasImage('custom-marker')) {
                this.map.removeImage('custom-marker')
                this.map.removeLayer('points')
                this.map.removeSource('point')
            }

            this.map.addImage('custom-marker', this.markerImage)

            if (!this.map.getSource('point')) {
                this.map.addSource('point', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [ this.selectedLngLat.lng, this.selectedLngLat.lat ]
                                }
                            }
                        ]
                    }
                })
            }
            this.map.addLayer({
                'id': 'points',
                'type': 'symbol',
                'source': 'point',
                'layout': {
                    'icon-image': 'custom-marker'
                }
            })

            this.map.flyTo({ center: this.selectedLngLat, zoom: 14 })
        },
        addAreaLayers(polygon) {

            this.map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: polygon,
                },
                paint: {
                    'fill-color': '#00775c',
                    'fill-opacity': 0.5,
                },
            })
            this.map.addLayer({
                id: 'polygon-border-layer',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: polygon,
                },
                paint: {
                    'line-color': '#00775c',
                    'line-width': 1.5,
                },
            })
        },
        removeAreaLayers() {

            if (this.map.getLayer('polygon-layer')) {
                this.map.removeLayer('polygon-layer')
                this.map.removeSource('polygon-layer')
            }

            if (this.map.getLayer('polygon-border-layer')) {
                this.map.removeLayer('polygon-border-layer')
                this.map.removeSource('polygon-border-layer')
            }

        },
        async getAddress() {
            try {
                const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                    this.selectedLngLat.lat + ',' + this.selectedLngLat.lng +
                    '&key=AIzaSyBhvQseKvw7t0IzAXw20ElTgeEBzoHK0o8')

                const data = response.data

                if (data.error_message) {
                    console.log(data.error_message)
                } else {
                    const address = data.results[ 0 ].formatted_address

                    this.setAddress(address)
                }
            } catch (err) {
                console.log(err)
            }
        }
    },
    watch: {
        selectedLngLat: {
            handler() {
                this.selectedLngLat && this.getAddress()
            },
            deep: true
        },
        address() {
            if (this.address) {
                this.addMarker()

                const polygon = getCircularArea(this.selectedLngLat)

                this.removeAreaLayers()

                this.addAreaLayers(polygon)

                Promise.all([
                    apiService.getCensus(this.selectedLngLat),
                    apiService.getRent(this.selectedLngLat),
                    apiService.getMarkers(this.selectedLngLat),
                    apiService.getConsumption(this.selectedLngLat),
                    apiService.getRentalDistribution(this.selectedLngLat),
                    apiService.getSaleDistribution(this.selectedLngLat)
                ])
                    .then(([ censusData, rentData, markersData, consumptionData, rentalDistributionData, saleDistributionData ]) => {


                        this.setAreaPopulation(censusData.data[ 0 ].metric_total_population)

                        const maleData = formatPopulationByAge(censusData.data[ 0 ].distribution_male_population_by_age, 'male')
                        const femaleData = formatPopulationByAge(censusData.data[ 0 ].distribution_female_population_by_age, 'female')
                        this.setMalePopulationByAge(maleData)
                        this.setFemalePopulationByAge(femaleData)

                        this.setAreaRent(rentData.data[ 0 ].metric_median_consumption_unit_income)

                        const tradeMarkers = getTradeMarkers(markersData.data)
                        const topTradeMarkers = getTopMarkers(tradeMarkers)
                        this.setMarkers(topTradeMarkers)

                        const allMarkers = getAllMarkers(markersData.data)
                        this.setMarkers(allMarkers)

                        const consumption = consumptionData.data[ 0 ].categories_consumption
                        this.setConsumption(consumption)

                        const rentalDistribution = rentalDistributionData.data[ 0 ].rental_area_distribution
                        this.setRentalDistribution(rentalDistribution)

                        const saleDistribution = saleDistributionData.data[ 0 ].sale_area_distribution
                        this.setSaleDistribution(saleDistribution)
                    })

                    .catch(error => {
                        console.error('An error occurred:', error)

                        this.message.error("Lo sentimos, hemos experimentado un problema con los datos. Selecciona otro punto del mapa.", { duration: 2000 })
                        this.setFailedReport(true)
                    })
            }
        }
    },
    mounted() {
        this.setSelectedLngLat(null)
        this.setAddress(null)

        mapboxgl.accessToken = 'pk.eyJ1IjoienpkYXRhbGFicyIsImEiOiJja2oxcG9ucXYwbXh0MzFtaGM0aDMyYXpiIn0.CkS9zfqeLhIHwcSWgKNRwA'

        this.markerImage = new Image()
        this.markerImage.src = '/map-pin-green.svg'


        this.markerImage.onload = () => {
            this.initializeMap()
        }
    }
}
</script>


<style scoped>
#map {
    margin: 0 24px 68px;
    height: 581px;
    max-width: 100%;
}

@media (min-width: 834px) {
    #map {
        margin: 100px 40px;
        height: 631px;
    }
}

@media (min-width: 1280px) {
    #map {
        margin: 100px 68px;
        height: 631px;
    }
}
</style>