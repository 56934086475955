<template>
    <div class="language-switcher">
        <n-select v-model:value="selectedLanguage" :options="options" @update:value="changeLanguage" size="tiny"
            :consistent-menu-width="false" />
    </div>
</template>


<script>
import { NSelect } from 'naive-ui'

export default {
    name: 'LanguageSwitcher',
    components: {
        NSelect
    },

    data() {
        return {
            options: [
                { label: 'Castellano', value: 'es' },
                { label: 'Euskera', value: 'eu' }
            ],
            selectedLanguage: this.$i18n.locale
        }
    },

    methods: {
        changeLanguage(newValue) {
            this.$i18n.locale = newValue
        }
    },

    watch: {
        selectedLanguage(newVal) {
            this.$i18n.locale = newVal
        }
    }
}
</script>


<style scoped>
.language-switcher {
    padding: 5px 10px;
}
</style>