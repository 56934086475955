const formatPopulationByAge = (data) => {
    const dataKeys = Object.keys(data)
    let formattedData = []

    dataKeys.forEach(elm => formattedData.push(data[elm]))

    const isEmpty = formattedData.filter(elm => elm != 0)

    if (isEmpty.length) {
        return formattedData.reverse()
    } else {
        return isEmpty
    }
}

export default formatPopulationByAge
