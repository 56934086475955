<template>
    <div class="container-chart" v-if="hasValidCategoryCounts">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-else>
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>{{ $t('errorChart.title') }}</h5>
            <h6>{{ $t('errorChart.text') }}<strong> {{ $t('errorChart.strong') }}</strong>
            </h6>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'LargeCategoryChart',
    computed: {
        ...mapState([ 'areaData' ]),
        markers() {
            return this.areaData.markers || []
        },
        hasValidCategoryCounts() {
            if (this.markers && Array.isArray(this.markers)) {
                const filteredMarkers = this.markers.filter(marker => marker.category !== 'Entorno');
                const categoryCounts = filteredMarkers.reduce((acc, marker) => {
                    acc[ marker.name ] = acc[ marker.name ] || { name: marker.name, count: 0 };
                    acc[ marker.name ].count += marker.count;
                    return acc;
                }, {});

                const topCategories = Object.values(categoryCounts)
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 10);

                return topCategories.some(count => count.count !== 0);
            }
            return false;
        }
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 300,
                },
                colors: [ '#00775c' ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: this.$t('categoryChart.xAxis'),
                        offsetY: 300,

                    },
                    labels: {
                        rotate: -45,
                        rotateAlways: true,
                        formatter: function (val) {
                            return val
                        },
                        offsetY: 5
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('categoryChart.yAxis'),

                    },
                },
                grid: {
                    padding: {
                        bottom: -50
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return `${val} ${this.$t('categoryChart.tooltip')}`
                        }
                    }
                }
            }
        }
    },
    watch: {
        markers: {
            handler(newVal) {
                if (newVal && Array.isArray(newVal)) {
                    const filteredMarkers = newVal.filter(marker => marker.category !== 'Entorno')
                    const categoryCounts = filteredMarkers.reduce((acc, marker) => {
                        if (!acc[ marker.name ]) {
                            acc[ marker.name ] = { name: marker.name, count: 0 }
                        }
                        acc[ marker.name ].count += marker.count
                        return acc
                    }, {})

                    const topCategories = Object.values(categoryCounts).sort((a, b) => b.count - a.count).slice(0, 10)

                    this.series = [ {
                        data: topCategories.map(count => count.count),
                        name: 'Establecimientos'
                    } ]
                    this.chartOptions.xaxis.categories = topCategories.map(count => count.name)
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.setResponsiveFontSize()
        window.addEventListener('resize', this.setResponsiveFontSize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setResponsiveFontSize)
    },
    methods: {
        setResponsiveFontSize() {
            const chartFontSize = window.innerWidth < 600 ? '10px' : window.innerWidth < 1024 ? '12px' : '12px'
            document.documentElement.style.setProperty('--chart-font-size', chartFontSize)
        }
    }
}
</script>


<style scoped>
.container-chart {
    width: 100%;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}

.apexcharts-xaxis-title {
    margin-top: 20px;
}
</style>
