const categoryMap = {
    'Supermercado': 'Comercio',
    'Gran superficie': 'Comercio',
    'Ultramarinos': 'Comercio',
    'Frutería': 'Comercio',
    'Mercado': 'Comercio',
    'Restaurante': 'Ocio y Hostelería',
    'Bar': 'Ocio y Hostelería',
    'Cafetería': 'Ocio y Hostelería',
    'Pub': 'Ocio y Hostelería',
    'Comida rápida': 'Ocio y Hostelería',
    'Heladería': 'Ocio y Hostelería',
    'Discoteca': 'Ocio y Hostelería',
    'Atracción turística': 'Ocio y Hostelería',
    'Parque de atracciones': 'Ocio y Hostelería',
    'Cine': 'Ocio y Hostelería',
    'Teatro': 'Ocio y Hostelería',
    'Museo': 'Ocio y Hostelería',
    'Galería de arte': 'Ocio y Hostelería',
    'Pista de skateboard': 'Ocio y Hostelería',
    'Cancha': 'Ocio y Hostelería',
    'Campo de tenis': 'Ocio y Hostelería',
    'Campo de fútbol': 'Ocio y Hostelería',
    'Campo de baloncesto': 'Ocio y Hostelería',
    'Pista de pádel': 'Ocio y Hostelería',
    'Campo de golf': 'Ocio y Hostelería',
    'Gimnasio': 'Ocio y Hostelería',
    'Polideportivo': 'Ocio y Hostelería',
    'Piscina': 'Ocio y Hostelería',
    'Parque urbano': 'Servicios',
    'Fuente': 'Servicios',
    'Parque infantil': 'Servicios',
    'Gasolinera': 'Transporte',
    'Estación de bus': 'Transporte',
    'Estación ferroviaria': 'Transporte',
    'Metro': 'Transporte',
    'Parada de bus': 'Transporte',
    'Parada de taxi': 'Transporte',
    'Parada de tranvía': 'Transporte',
    'Alquiler de coches': 'Transporte',
    'Taquillas para equipaje': 'Servicios',
    'Garaje': 'Transporte',
    'Aparcamiento': 'Transporte',
    'Aparcamiento de bicis': 'Transporte',
    'Colegio': 'Servicios',
    'Centro de estudios': 'Servicios',
    'Universidad': 'Servicios',
    'Guardería': 'Servicios',
    'Biblioteca': 'Servicios',
    'Autoescuela': 'Servicios',
    'Hospital': 'Salud y Bienestar',
    'Clínica': 'Salud y Bienestar',
    'Farmacia': 'Salud y Bienestar',
    'Dentista': 'Salud y Bienestar',
    'Veterinario': 'Salud y Bienestar',
    'Banco': 'Servicios',
    'Agencia de viaje': 'Comercio',
    'Oficina de correos': 'Servicios',
    'Juzgado': 'Servicios',
    'Ayuntamiento': 'Servicios',
    'Policía': 'Servicios',
    'Estación de bomberos': 'Servicios',
    'Casa de apuestas': 'Servicios',
    'Reciclaje': 'Servicios',
    'Buzón': 'Servicios',
    'Papelera': 'Servicios',
    'Trasteros personales': 'Servicios',
    'Almacén': 'Servicios',
    'Autolavado': 'Servicios',
    'Taller': 'Comercio',
    'Lavandería': 'Comercio',
    'Punto de información': 'Servicios',
    'Cabina telefónica': 'Servicios',
    'Asiento': 'Servicios',
    'Juguetería': 'Comercio',
    'Quiosco': 'Comercio'
}
export default function mapCategories(markers) {
    const categoryCounts = markers.reduce((acc, marker) => {
        const newCategory = categoryMap[marker.name] || marker.category
        if (newCategory === 'Entorno') {
            return acc
        }
        if (!acc[newCategory]) {
            acc[newCategory] = 0
        }
        acc[newCategory] += marker.count
        return acc
    }, {})

    const categoryCountsArray = Object.entries(categoryCounts).map(([category, count]) => ({ category, count }))
    return categoryCountsArray
}