<template>
    <div class="container-chart" v-if="hasValidConsumptionValues">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-else>
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>{{ $t('errorChart.title') }}</h5>
            <h6>{{ $t('errorChart.text') }}<strong> {{ $t('errorChart.strong') }}</strong>
            </h6>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'
import getConsumptionTags from '../../utils/getConsumptionTags'
import getConsumptionValues from '../../utils/getConsumptionValues'

export default {
    name: 'CategoryConsumptionChart',
    computed: {
        ...mapState([ 'areaData' ]),
        hasValidConsumptionValues() {
            if (this.areaData && this.areaData.consumptions) {
                const values = getConsumptionValues(this.areaData.consumptions);
                return values.some(value => value !== 0);
            }
            return false;
        }
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: [ '#00775c' ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: this.$t('categotyConsumptionChart.xAxis')
                    },
                    labels: {
                        rotate: -45,
                        style: {
                            fontSize: '12px'
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('categotyConsumptionChart.yAxis')
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return `${val} ${this.$t('categotyConsumptionChart.tooltip')}`
                        }
                    }
                }

            }
        }
    },
    watch: {
        areaData: {
            handler(newVal) {
                if (newVal && newVal.consumptions && typeof newVal.consumptions === 'object') {
                    this.series = [ {
                        data: getConsumptionValues(newVal.consumptions),
                        name: 'Comercios'
                    } ]
                    this.chartOptions.xaxis.categories = getConsumptionTags(newVal.consumptions)

                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (this.areaData && this.areaData.consumptions && typeof this.areaData.consumptions === 'object') {
            this.series = [ {
                data: getConsumptionValues(this.areaData.consumptions),
                name: 'Comercios'
            } ]
            this.chartOptions.xaxis.categories = getConsumptionTags(this.areaData.consumptions)
        }
    }
}
</script>


<style scoped>
.container-chart {
    width: 100%;
    max-width: 100%;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}
</style>
