
const getConsumptionTags = (data) => {
    if (typeof data !== 'object') return []
    const tags = {
        clothing_and_footwear_consumption: 'Textil',
        education_consumption: 'Enseñanza',
        food_and_beverages_consumption: 'Alimentación',
        furniture_home_goods_consumption: 'Hogar',
        healthcare_consumption: 'Sanidad',
        leisure_and_culture_consumption: 'Ocio',
        restaurants_and_hotels_consumption: 'Restauración'
    }
    return Object.keys(data).map(key => tags[key] || key)
}

export default getConsumptionTags