<template>
    <div class="container chart" v-if="hasValidRentalValues">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-else>
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>{{ $t('errorChart.title') }}</h5>
            <h6>{{ $t('errorChart.text') }}<strong> {{ $t('errorChart.strong') }}</strong>
            </h6>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'
import getRentalValues from '../../utils/getRentalValues'

export default {
    name: 'RentalDataChart',
    computed: {
        ...mapState([ 'areaData' ]),
        hasValidRentalValues() {
            if (this.areaData && this.areaData.rentalDistributions) {
                const values = getRentalValues(this.areaData.rentalDistributions);
                return values.some(value => value !== 0);
            }
            return false;
        }
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: [ '#00775c' ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [ '< 100 m2', '100-200 m2', '200-300 m2', '300-400 m2', '400-500 m2', '>500 m2' ],
                },
                yaxis: {
                    title: {
                        text: this.$t('rentalDataChart.title')
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return `${val} ${this.$t('rentalDataChart.tooltip')}`
                        }
                    }
                }
            }
        }
    },
    watch: {
        'areaData.rentalDistributions': {
            handler(newValue) {
                if (newValue) {
                    this.series = [ {
                        name: 'Locales',
                        data: getRentalValues(newValue)
                    } ]
                } else {
                    this.series = []
                }
            },
            immediate: true
        }
    },
    mounted() {
        if (this.areaData.rentalDistributions) {
            this.series = [ {
                name: 'Locales',
                data: getRentalValues(this.areaData.rentalDistributions)
            } ]

        }
    }
}
</script>


<style scoped>
.container-chart {
    width: 562px;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}
</style>
