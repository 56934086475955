const getRentalValues = (rentalDistribution) => {
    return [
        rentalDistribution.area_0 || 0,
        rentalDistribution.area_100 || 0,
        rentalDistribution.area_200 || 0,
        rentalDistribution.area_300 || 0,
        rentalDistribution.area_400 || 0,
        rentalDistribution.area_500 || 0
    ];
}

export default getRentalValues