<template>
    <div class="container-chart" v-if="hasValidCategoryCounts">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-else>
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>{{ $t('errorChart.title') }}</h5>
            <h6>{{ $t('errorChart.text') }}<strong> {{ $t('errorChart.strong') }}</strong>
            </h6>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'
import mapCategories from '../../utils/mapCategories'

export default {
    name: 'LargeCategoryChart',
    computed: {
        ...mapState([ 'areaData' ]),
        markers() {
            return this.areaData.markers || []
        },
        hasValidCategoryCounts() {
            if (this.markers && Array.isArray(this.markers)) {
                const categoryCounts = mapCategories(this.markers);
                return categoryCounts.some(count => count.count !== 0);
            }
            return false;
        }
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: [ '#00775c' ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: false,
                        columnWidth: '50%',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: this.$t('categoryChart.xAxis'),
                    },
                    labels: {
                        rotate: -45,
                        style: {
                            fontSize: '12px'
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('categoryChart.yAxis')
                    }
                },
                grid: {
                    padding: {
                        bottom: 20
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return `${val} ${this.$t('categoryChart.tooltip')}`
                        }
                    }
                }
            }
        }
    },
    watch: {
        markers: {
            handler(newVal) {
                if (newVal && Array.isArray(newVal)) {
                    const categoryCounts = mapCategories(newVal)

                    this.series = [ {
                        data: categoryCounts.map(count => count.count),
                        name: this.$t('rentalDataChart.title')
                    } ]
                    this.chartOptions.xaxis.categories = categoryCounts.map(count => count.category)

                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>


<style scoped>
.container-chart {
    width: 100%;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}

.apexcharts-xaxis-title {
    margin-top: 20px;
}
</style>
