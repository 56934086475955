<template>
    <div class="flex">

        <div class="chart-card-container">
            <p>{{ $t('chartCards.title1') }}</p>
            <PopulationByAgeChart />
        </div>

        <div class="chart-card-container">
            <p>{{ $t('chartCards.title2') }}</p>
            <CategoryConsumptionChart />
        </div>

    </div>
    <div class="flex">

        <div class="chart-card-container">
            <p>{{ $t('chartCards.title3') }}</p>
            <LargeCategoryChart />
        </div>
        <div class="chart-card-container">
            <p>{{ $t('chartCards.title4') }}</p>
            <SmallCategoryChart />
        </div>

    </div>
    <div class="flex">

        <div class="chart-card-container">
            <p>{{ $t('chartCards.title5') }}</p>
            <RentalDataChart />
        </div>
        <div class="chart-card-container">
            <p>{{ $t('chartCards.title6') }}</p>
            <SaleDataChart />
        </div>

    </div>
</template>


<script>
import PopulationByAgeChart from './Charts/PopulationByAgeChart.vue'
import CategoryConsumptionChart from './Charts/CategoryConsumptionChart.vue'
import LargeCategoryChart from './Charts/LargeCategoryChart.vue'
import SmallCategoryChart from './Charts/SmallCategoryChart.vue'
import RentalDataChart from './Charts/RentalDataChart.vue'
import SaleDataChart from './Charts/SaleDataChart.vue'

export default {
    name: 'ChartCards',
    components: {
        PopulationByAgeChart,
        CategoryConsumptionChart,
        LargeCategoryChart,
        SmallCategoryChart,
        RentalDataChart,
        SaleDataChart
    }
}
</script>


<style scoped>
.chart-card-container {
    margin-bottom: 20px;
    padding: 28px 44px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

h6 {
    margin-bottom: 8px;
    margin-top: 0;
    padding: 0 5px;
    font-size: 18px;
}

@media (min-width: 1280px) {
    .flex {
        padding-top: 0;
        flex-direction: row;
    }
}
</style>
