<template>
    <HighlightBlock />

    <div class="home-container">

        <IconCards />
        <ListSection />
        <DiscoverCard />

    </div>
</template>


<script>
import HighlightBlock from '../components/HighlightBlock.vue'
import IconCards from '../components/IconCards.vue'
import ListSection from '../components/ListSection.vue'
import DiscoverCard from '../components/DiscoverCard.vue'

export default {
    name: 'HomePage',
    components: {
        HighlightBlock,
        IconCards,
        ListSection,
        DiscoverCard
    }
}
</script>


<style scoped>
.home-container {
    width: calc(100% - 48px);
    margin: 0 auto;
    overflow-x: hidden;
}

section {
    margin: 100px 0;
}

@media (min-width: 834px) {
    .home-container {
        margin: 0 40px;
        width: calc(100% - 80px);
    }
}
</style>