<template>
    <div class="communication-cards-container">
        <h2>{{ $t('communicationCards.title') }}</h2>

        <p>{{ $t('communicationCards.text') }}</p>

        <div class="cards-container">
            <CommunicationCardContent :detail="$t('communicationCards.cardDetail')"
                :title="$t('communicationCards.card1Title')" :text="$t('communicationCards.card1Text')" img="report"
                url="https://www.vitoria-gasteiz.org/j31-01w/formularioViewer/create?lang=es&locale=es&idioma=es&formularioId=203" />

            <CommunicationCardContent :detail="$t('communicationCards.cardDetail')"
                :title="$t('communicationCards.card2Title')" :text="$t('communicationCards.card2Text')" img="support"
                url="https://www.vitoria-gasteiz.org/wb021/was/contenidoAction.do?idioma=es&uid=u5963c84a_179d0145f4c__7fcd" />
        </div>
    </div>
</template>


<script>
import { mapState } from 'vuex'
import CommunicationCardContent from './CommunicationCardContent.vue'

export default {
    name: 'CommunicationCards',
    components: {
        CommunicationCardContent
    },
    computed: {
        ...mapState([ 'screenWidth' ])
    }
}
</script>


<style scoped>
.communication-cards-container {
    padding: 100px 26px;
}

h2 {
    margin: 0 0 16px 0;
}

p {
    margin: 0;
    margin-bottom: 68px;
}

.cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (min-width: 834px) {
    .communication-cards-container {
        padding: 100px 40px;
    }

    .cards-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}


@media (min-width: 1280px) {
    .communication-cards-container {
        padding: 40px 68px 50px 68px;
    }
}
</style>