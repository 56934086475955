import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueApexCharts from 'vue3-apexcharts'
import { createI18n } from 'vue-i18n';

import es from './locales/es.json';
import eu from './locales/eu.json';

const i18n = createI18n({
   locale: 'es',
   fallbackLocale: 'es',
   messages: {
      es: es,
      eu: eu
   }
});

createApp(App).use(store).use(router).use(VueApexCharts).use(i18n).mount('#app')