<template>
    <section class="card-icon-container">
        <h3>{{ $t('iconCards.title0') }}</h3>

        <div class="flex">
            <IconCardInfo :isFirstArticle="true" img="AI" :title="$t('iconCards.title1')"
                :text="$t('iconCards.text1')" />

            <IconCardInfo img="balance" :title="$t('iconCards.title2')" :text="$t('iconCards.text2')" />

            <IconCardInfo img="User" :title="$t('iconCards.title3')" :text="$t('iconCards.text3')" />
        </div>

    </section>
</template>


<script>
import IconCardInfo from './IconCardInfo.vue'

export default {
    name: 'IconCards',
    components: {
        IconCardInfo
    }
}
</script>


<style scoped>
h3 {
    text-align: start;
    margin-bottom: 68px;
}

@media (min-width: 834px) {
    .flex {
        display: flex;
    }

    h3 {
        margin-bottom: 68px;
    }

}
</style>